import React from 'react';
import dayjs from 'dayjs';
import gsap from 'gsap';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import TextField from '@mui/material/TextField';

import { servicesData } from '../../../mocks/services';
import { sendBookingEmail } from '../../../actions';
import { formatDate } from '../../../utils';

import check from '../../../assets/check.svg';
import error from '../../../assets/error.svg';

import { ModalStyled } from './style';
import Button from '../button';

const Modal = (props) => {
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [comment, setComment] = React.useState('');
    const [dateFrom, setDateFrom] = React.useState(dayjs());
    const [dateTo, setDateTo] = React.useState(dayjs());
    const [registered, setRegistered] = React.useState(false);

    const service = servicesData.find(service => service.id === props.title);

    const handleConfirmBooking = async () => {
        await sendBookingEmail({
            name: `${firstName} ${lastName}`,
            email: email,
            phone: phoneNumber,
            price: service.price,
            content: service.subtitle,
            title: service.title,
            text: comment,
            dateFrom: formatDate(new Date(dateFrom)),
            dateTo: formatDate(new Date(dateTo))
        }).then(() => setRegistered(true))
        .catch(() => setRegistered(false))

        gsap.fromTo('.data', {opacity: 1, x: 0, display: 'block'}, { opacity: 0, x: -200, display: 'none', duration: 0.5});
        gsap.fromTo('.thankyou', {opacity: 0, x: 200 }, { opacity: 1, display: 'flex', x: 0 , duration: 0.5, delay: 0.5 });

        // console.log('response', response);
    }

    return (
        <ModalStyled>
            <div className='modal'>
                <div className='header'>
                    <div>
                        <h4>{service?.title} - <b>{service?.price}€</b></h4>
                        <span>{service?.subtitle}</span>
                    </div>
                    <svg className='close-svg' onClick={props.onClick} fill="#00000099" height="200px" width="200px" version="1.1" viewBox="0 0 460.775 460.775"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path> </g></svg>
                </div>
                <div className='data'>
                    <div className='content'>
                        <TextField id="outlined-basic" label="First Name" variant="outlined" onChange={e => setFirstName(e.target.value)} />
                        <TextField id="outlined-basic" label="Last Name" variant="outlined" onChange={e => setLastName(e.target.value)} />
                        <TextField id="outlined-basic" label="Email" variant="outlined" onChange={e => setEmail(e.target.value)} />
                        <TextField id="outlined-basic" label="Phone Number" variant="outlined" onChange={e => setPhoneNumber(e.target.value)} />
                        <TextField
                            id="filled-multiline-flexible"
                            label="Comment/Note"
                            multiline
                            maxRows={4}
                            variant="outlined"
                            onChange={e => setComment(e.target.value)}
                        />
                        <span className='date-label'>Select your available period for kitesurfing lessons</span>
                        <div className='date-picker'>
                            <LocalizationProvider dateAdapter={AdapterDayjs} className='date-picker'>
                                <DemoContainer components={['MobileDatePicker']}>
                                    <MobileDatePicker defaultValue={dayjs(new Date())} label='Start' minDate={dayjs()} onChange={value => { setDateFrom(dayjs(new Date(value.$d))); setDateTo(dayjs(new Date(value.$d))) }}/>
                                    –
                                    <MobileDatePicker defaultValue={dayjs(new Date(dateFrom))} label='End' minDate={dayjs(new Date(dateFrom))} value={dayjs(new Date(dateTo))} onChange={value => setDateTo(dayjs(new Date(value.$d)))}/>
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>
                    </div>

                    <div className='footer'>
                        <Button title='Confirm Booking' className={['modal-button', (!firstName || !lastName || !email || !dateFrom) ? 'disabled' : ''].join(' ')} onClick={() => handleConfirmBooking()} />
                    </div>
                </div>
                <div className='thankyou'>
                    {registered ? <img src={check} alt="Check"/> : <img src={error} alt="Fail"/>}
                    <h3>{registered ? 'Thank you!' : 'Oooops!'}</h3>
                    {registered
                        ? <p>Thank you for booking your lesson at Surf Club Reful! We’re excited to have you join us and can’t wait to see you on the water. You’ll be contacted soon with all the details to ensure you're all set for your session.<br/><br/>See you soon!</p>
                        : <p>Something went wrong. Please try againg later or contact us directly via email.<br/><br/>Contact email: <a href='mailto:info@reful.hr'>info@reful.hr</a></p>
                    }
                </div>
            </div>
        </ModalStyled>
    );
};

export default Modal;
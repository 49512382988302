import styled from 'styled-components';

export const ModalStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100lvh;
    position: absolute;
    background: #80808085;
    z-index: 1000;

    .modal {
        width: 90%;
        max-width: 500px;
        background: white;
        border-radius: 15px;
        position: relative;
    }

    .thankyou {
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 2rem;

        img {
            width: 40px;
        }
    }

    .header {
        padding: 0 1.5rem;
        border-bottom: 1px solid #00000038;
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;

        >div {
            margin-bottom: 1.33em;
        }

        h4{
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 300;
            font-family: "Barlow Condensed", serif;
            margin-bottom: 0;
        }

        .close-svg {
            max-width: 15px;
            height: auto;
            cursor: pointer;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 1.5rem;

        .date-label {
            color: rgba(0, 0, 0, 0.6);
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
        }

        .date-picker > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
            width: 100%;

            > div {
                margin: 0;
                min-width: unset;
            }
        }
    }

    .footer {
        padding: 1.5rem;
        border-top: 1px solid #00000038;
        display: flex;
        justify-content: flex-end;

        .modal-button {
            font-size: 18px;
            font-size: 16px;
            text-transform: capitalize;
            border: 1px solid #107E7D;
            background: #107E7D;
            color: white;

            &.disabled {
                border: 1px solid #107e7dcf;
                background: #107e7dcf;
                cursor: none;
                pointer-events: none;
            }

            &:hover {
                background: #106f6e;
            }
        }
    }
`;
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import { GalleryStyled } from './style';

import image1 from '../../assets/gallery/reful-privlaka.jpg';
import image2 from '../../assets/gallery/instructor.jpg';
import image3 from '../../assets/gallery/instructor1.jpg';
import image4 from '../../assets/gallery/kitesurf-drive.jpg';
import image5 from '../../assets/gallery/kitesurf-drive1.jpg';
import image6 from '../../assets/gallery/kitesurf.jpg';
import image7 from '../../assets/gallery/learning.jpg';
import image8 from '../../assets/gallery/reful-sea-side.webp';
import image9 from '../../assets/gallery/reful-spot.jpg';
import image10 from '../../assets/gallery/student.jpg';
import image11 from '../../assets/gallery/students.jpg';
import image13 from '../../assets/gallery/velebit.jpg';

const Gallery = () => {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3,
          slidesToSlide: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 600 },
          items: 2,
          slidesToSlide: 1,
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          slidesToSlide: 1,
        }
    };

    return (
        <>
            <GalleryStyled>
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    ssr={true}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    transitionDuration={500}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                >
                    <div class="image"><img src={image3} alt="Kitesurfing instrucotor"/></div>
                    <div class="image"><img src={image1} alt="Kitesurfing privlaka spot"/></div>
                    <div class="image"><img src={image7} alt="Kitesurfing instrucotor lesson"/></div>
                    <div class="image"><img src={image4} alt="Learning kitesurfing in the sea"/></div>
                    <div class="image"><img src={image9} alt="Kitesurfing instrucotor"/></div>
                    <div class="image"><img src={image6} alt="Kitesurfing spot"/></div>
                    <div class="image"><img src={image8} alt="Kitesurfing sea side spot"/></div>
                    <div class="image"><img src={image2} alt="Kitesurfing lesson" /></div>
                    <div class="image"><img src={image11} alt="Kitesurfing instrucotor"/></div>
                    <div class="image"><img src={image10} alt="Students learning to kitesurf"/></div>
                    <div class="image"><img src={image5} alt="Kitesurfing ride in the sea"/></div>
                    <div class="image"><img src={image13} alt="Kitesurfing with velebit view"/></div>
                </Carousel>
            </GalleryStyled>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ background:"#283D58", marginTop: '-2px' }}>
                <path fill="#107E7D" fill-opacity="1" d="M0,224L1440,192L1440,0L0,0Z"></path>
            </svg>
        </>
    );
};

export default Gallery;
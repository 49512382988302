import styled from 'styled-components';

export const GalleryStyled = styled.div`
    max-height: 500px;
    background: #107E7D;
    height: 100%;
    position: relative;

    .carousel-container {
        height: 100%;
        width: 100%;
        
        .image {
            overflow: hidden;
            display: flex;
            justify-content: center;

            img {
                width: 95%;
            }
        }

        .react-multi-carousel-track {
            z-index: 100;
        }
    }
`;
import styled, { keyframes } from 'styled-components';
const animate = keyframes`
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 55%,
			54% 65%,
			70% 70%,
			84% 62%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}`

export const HeaderWrapperStyled = styled.div`
    z-index: 100;
    position: relative;
    height: 0;

    @media (max-width: 1100px) {
        display: none;
    }

    svg {
        max-width: 150px;
        height: auto;
        position: relative;
    }

    .reful-logo {
        @media (max-width: 800px) {
           opacity: 0.6;
        }
    }

    .svg-path {
        position: absolute;
        display: flex;
        top: 150px;
        z-index: 100;

        path {
            stroke: transparent;
        }
    }
`

export const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 60px 0 25px 0;
    max-width: 1320px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    z-index: 1000;
    gap: 20%;

    @media (max-width: 1320px) {
        padding: 60px 4rem 25px 4rem;
        box-sizing: border-box;
        column-gap: 7vw;
    }

    @media (max-width: 1100px) {
        padding: 25px 2rem;
        justify-content: center;
    }

    svg {
        max-width: 150px;
        height: auto;
        position: relative;
    }

    .logo-animation {
        position: relative;

        .header-logo-main {
            fill: #107E7D;
            filter: transparent;

            @media (max-width: 1100px) {
                fill: #107E7D;
            }
        }

        .header-logo {
            left: 0;
            position: absolute;
            animation: ${animate} 4s ease-in-out infinite;
        }
    }

    .navigation {
        display: flex;
        column-gap: 20px;
        color: #E3B505;
        text-transform: uppercase;
        font-weight: 600;

        @media (max-width: 1100px) {
            display: none;
        }

        span {
            cursor: pointer;
            position: relative;
            padding-bottom: 5px;

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                transform: scaleX(0);
                height: 2px;
                bottom: 0;
                left: 0;
                background-color: #E3B505;
                transition: transform 0.25s ease-out;
                transform-origin: bottom right;
            }

            &:hover::after {
                transform: scaleX(1);
                transform-origin: bottom left;
            }
        }
    }
`;

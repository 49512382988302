export const servicesData = [
    {
        id: 1,
        price: 160,
        imageSrc: 'img/discovery.jpg',
        tagline: 'kitesurfing lesson',
        title: 'Discovery lesson',
        subtitle: 'Private Course (1 Student, 1 Instructor) – 2 Hours',
        description: '<ul><li><b>Duration</b>: 2 hours of focused, one-on-one instruction</li><li><b>Price</b>: €160</li><li><b>Perfect for Beginners</b>: Learn the basics of kitesurfing at your own pace</li></ul><p>Experience the thrill of kitesurfing with a personalized, one-on-one lesson. Perfect for beginners, this 2-hour session covers the basics, ensuring you have a safe and exciting introduction to the sport.</p>'
    },
    {
        id: 2,
        price: 420,
        imageSrc: 'img/learning_in_sea.jpg',
        tagline: 'kitesurfing lesson',
        title: 'Beginner kitecourse',
        subtitle: 'Private Course (1 Student, 1 Instructor) – 6 Hours',
        description: '<ul><li><b>Duration</b>: 6 hours of focused, one-on-one instruction</li><li><b>Price</b>: €420</li><li><b>Perfect for Beginners</b>: Master the fundamentals of kitesurfing at your own pace</li></ul><p>Kickstart your kitesurfing journey with a personalized, one-on-one lesson. This 6-hour private course is designed for beginners, providing a thorough introduction to kitesurfing with expert guidance. Get comfortable with the equipment, learn essential techniques, and gain the confidence to ride the board!</p>'
    },
    {
        id: 3,
        price: 420,
        imageSrc: 'img/advanced.jpg',
        tagline: 'kitesurfing lesson',
        title: 'Advanced course',
        subtitle: 'Private Course (1 Student, 1 Instructor) – 6 Hours',
        description: '<ul><li><b>Duration</b>: 6 hours of intensive, one-on-one instruction</li><li><b>Price</b>: €420</li><li><b>Perfect for Advanced Riders</b>: Refine your skills and take your kitesurfing to the next level</li></ul><p>Take your kitesurfing to new heights with our advanced private course, designed for those who are already comfortable with body dragging and want to elevate their skills. This 6-hour session focuses on refining technique, mastering transitions, and progressing to higher-level maneuvers, all with the personalized guidance of an experienced instructor.</p>'
    },
    {
        id: 4,
        price: 150,
        imageSrc: 'img/extra.jpg',
        tagline: 'kitesurfing lesson',
        title: 'Extra hours',
        subtitle: 'Private Course (1 Student, 1 Instructor) – 2 Extra Hours',
        description: "<ul><li><b>Duration</b>: 2 additional hours on the same holiday</li><li><b>Price</b>: €150</li><li><b>Extend Your Lesson</b>: Continue building your skills right after the 6-hour course</li></ul><p>Want more time on the water? Add 2 extra hours of focused instruction after your 6-hour course on the same holiday. Perfect for those looking to reinforce what they've learned and continue progressing in a single session.</p>"
    },
    {
        id: 5,
        price: 320,
        imageSrc: 'img/semi.jpg',
        tagline: 'kitesurfing lesson',
        title: 'Semi-Private lesson 6h',
        subtitle: 'Private Course (2 Students, 1 Instructor) – 6 Hours',
        description: "<ul><li><b>Duration</b>: 6 hours of shared instruction with one instructor</li><li><b>Price</b>: €320 per person</li><li><b>Semi-Private Lesson</b>: 2 students, 1 instructor for a personalized experience</li></ul><p>Perfect for those who want a more affordable option without compromising on personalized instruction. This 6-hour semi-private lesson gives you the chance to learn alongside one other student, with a dedicated instructor to guide both of you through the kitesurfing experience. Share the fun while still receiving individual attention!</p>"
    },
]
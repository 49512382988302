import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import { HeaderStyled, HeaderWrapperStyled } from './style';

import { ReactComponent as Logo } from '../../assets/logo.svg';

const Header = ({ setScrollTo }) => {
    const [logoColor, setLogoColor] = React.useState('#E3B505');

    React.useEffect(() => {
        gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);
        const content = document.querySelector(".content");
        const rocketPath = document.querySelector(".rocketPath-lg");
        const tl = gsap.timeline();
            
        tl.to(".reful-logo", {
            motionPath: {
                path: rocketPath,
                align: rocketPath,
                alignOrigin: [0.2, 0.2],
                autoRotate: 180
            },
            scrollTrigger: {
                trigger: rocketPath,
                scroller: content,
                start: "0",
                end: "bottom 70%",
                scrub: 1
            },
            ease: "none"
        });

        tl.from(rocketPath, {
            drawSVG: "0",
            ease: "none",
        }, 0)
    });

    return (
        <>
            <HeaderWrapperStyled>
                <Logo className="reful-logo" fill={logoColor} />
                <svg className={["rocketPathSVG", 'svg-path'].join(' ')} viewBox="0 0 205 10110">
                    <path className="rocketPath-lg" d="M289.7369 32.4336C278.6021 302.2956 181.0065 583.2924 169.8712 853.8088 158.7358 1124.3252 263.537 1916.2258 285.8069 2447.4339c22.2699 531.208-89.7353 737.5348-110.0407 1106.3021-20.3054 368.767 102.1809 720.505 115.9357 1086.652 13.7548 366.1471-98.2504 1591.0053-108.0757 2383.5599-9.8253 792.5549 106.766 861.9859 117.9008 1291.0133 11.1348 429.0275-75.527 923.0388-105.527 1166.0388C203 9622 281 9678 280 10141" fill="none" stroke="#008c99" stroke-miterlimit="10" stroke-width="5" stroke-dasharray="0 0 20.06 20.06" />
                </svg>
            </HeaderWrapperStyled>
            <HeaderStyled>
                <div className='logo-animation'>
                    <Logo className="header-logo-main" fill='#E3B505' />
                    <Logo className="header-logo" fill='#E3B505' />
                </div>
                <div className='navigation'>
                    <span onClick={() => setScrollTo(0)}>Home</span>
                    <span onClick={() => setScrollTo(1)}>About</span>
                    <span onClick={() => setScrollTo(2.7)}>Services</span>
                    <span onClick={() => setScrollTo(7)}>Contact</span>
                </div>
            </HeaderStyled>
        </>
    );
};

export default Header;
import React from 'react';
import { ButtonStyled } from './style';

const Button = (props) => {
    return (
        <ButtonStyled className={props.className} onClick={props.onClick} >
            {props.title}
        </ButtonStyled>
    );
};

export default Button;
import styled from 'styled-components';

export const ButtonStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 200px;
    padding: 10px;
    text-transform: capitalize;
    background-color: transparent;
    border-radius: 7px;
    line-height: 1.5;
    color: #403833;
    font-size: 20px;
    position: relative;
    border: 1px solid #107E7D;
    background: #107E7D;
    color: #E8F1E2;
    cursor: pointer;
    letter-spacing: 1px;
    text-transform: uppercase;

    &:hover {
        background: #107e7dcf;
        border: 1px solid #107e7dcf;
    }
`;
import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

import { ReactComponent as Logo } from '../../assets/logo.svg';

import { FooterStyled } from './style';

const Footer = () => {

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const content = document.querySelector(".content");

        gsap.fromTo(".reful-logo", 
            {
                y: -200,
                scale: 1
            },
            {
                y: 0,
                scale: 0,
                scrollTrigger: {
                    trigger: ".footer",
                    scroller: content,
                    start: "top 80%",
                    end: "top 60%",
                    scrub: true
                },
                duration: 3
            }
        );
        gsap.fromTo(".footer-logo", 
            {
                y: -200,
                scale: 0
            },
            {
                y: 0,
                scale: 1,
                scrollTrigger: {
                    trigger: ".footer",
                    scroller: content,
                    start: "top 80%",
                    end: "top 60%",
                    scrub: true
                },
                duration: 3
            }
        );
    });

    return (
        <FooterStyled>
            <div className='content'>
                <div className={['content-left', 'footer'].join(' ')}>
                    <Logo className="footer-logo" fill='#fff' />
                    <p>Sand beaches in Privlaka are the well-known kite spot in the lagoon.</p>
                    <div className='social-links'>
                        {/* <a href="https://www.facebook.com/KitesurfMaestral" target="_blank">
                            <svg fill="#E3B505" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"></path></g></svg>
                        </a> */}
                        <a href="https://www.instagram.com/surfclub_reful" target="_blank">
                            <svg viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 5H9.5C7.29086 5 5.5 6.79086 5.5 9V15C5.5 17.2091 7.29086 19 9.5 19H15.5C17.7091 19 19.5 17.2091 19.5 15V9C19.5 6.79086 17.7091 5 15.5 5Z" stroke="#E3B505" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 15C10.8431 15 9.5 13.6569 9.5 12C9.5 10.3431 10.8431 9 12.5 9C14.1569 9 15.5 10.3431 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z" stroke="#E3B505" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> <rect x="15.5" y="9" width="2" height="2" rx="1" transform="rotate(-90 15.5 9)" fill="#E3B505"></rect> <rect x="16" y="8.5" width="1" height="1" rx="0.5" transform="rotate(-90 16 8.5)" stroke="#E3B505" stroke-linecap="round"></rect> </g></svg>
                        </a>
                        <a href="mailto:info@reful.hr" target="_blank">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 5.25L3 6V18L3.75 18.75H20.25L21 18V6L20.25 5.25H3.75ZM4.5 7.6955V17.25H19.5V7.69525L11.9999 14.5136L4.5 7.6955ZM18.3099 6.75H5.68986L11.9999 12.4864L18.3099 6.75Z" fill="#E3B505"></path> </g></svg>
                        </a>
                    </div>
                </div>
                <div className='content-right'>
                    <h3>Userful Links</h3>
                    <ul>
                        <li><a target="_blank" href="https://www.windguru.cz/22206">Windguru.cz</a></li>
                        <li><a target="_blank" href="https://www.yr.no/place/Croatia/Other/Zagreb_TV_Tower/">Yr.no</a></li>
                        <li><a target="_blank" href="https://www.snow-forecast.com/resorts/Sljeme/6day/top">Snow-Forecast</a></li>
                        <li><a target="_blank" href="http://www.sljeme.hr/">Sljeme.hr</a></li>
                        <li><a target="_blank" href="http://wxcharts.eu/?panel=default&model=gfs,gfs,gfs,gfs&region=italy&chart=overview,850temp,wind10mkph,snowdepth&run=06&step=012&plottype=10&lat=51.500&lon=-0.250&skewtstep=0">Wxcharts.eu</a></li>
                        <li><a target="_blank" href="http://www.meteo.hr/">Meteo.hr</a></li>
                        <li><a target="_blank" href="http://www.jeti.hr/">Jeti.hr</a></li>
                    </ul>
                </div>
            </div>
            <div className='copyright'>Copyright © 2025 Surf club Reful. All Rights Reserved.</div>
        </FooterStyled>
    );
};

export default Footer;
import React from 'react';
// import { TitleStyled } from './style';
import About from '../../components/about';
import Services from '../../components/services';
import Vaucher from '../../components/vaucher';
import Gallery from '../../components/gallery';
import Contact from '../../components/contact';

const Homepage = () => {
    return (
		<>
        	<About />
        	<Services />
        	<Vaucher />
        	<Gallery />
        	<Contact />
		</>
    );
};

export default Homepage;
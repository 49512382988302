import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

import { servicesData } from '../../mocks/services';

import Button from '../UI/button';
import { ServicesStyled } from './style';

import { useStore } from '../../store';


const Services = () => {
    const { updateSelectedBooking } = useStore();

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const content = document.querySelector(".content");

        const titleScrollTrigger = (number) => ({
            scrollTrigger: {
                trigger: `.services-list-item${number}-title`,
                scroller: content,
                start: "top 70%",
                end: "top 50%",
                scrub: true
            }
        });
    
        const textScrollTrigger = (number) => ({
            scrollTrigger: {
                trigger: `.services-list-item${number}`,
                scroller: content,
                start: "top 60%",
                end: "top 40%",
                scrub: true
            }
        });
    
        const imageScrollTrigger = (number) => ({
            scrollTrigger: {
                trigger: `.services-list-item${number}`,
                scroller: content,
                start: "top 60%",
                end: "top 40%",
                scrub: true
            }
        });

        gsap.fromTo(".services-title", 
            {
                y: -200,
                opacity: 0
            },
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: ".services-title",
                    scroller: content,
                    start: "top 80%",
                    end: "top 30%",
                    scrub: true
                },
                duration: 3
            }
        );

        // list item
        gsap.fromTo(".services-list-item1-image", { x: -80 }, { x: 0, ...imageScrollTrigger(1), duration: 3, ease: 'power1.inOut' });
        gsap.fromTo(".services-list-item1-content", { x: 80 }, { x: 0, ...textScrollTrigger(1), duration: 3 });
        gsap.fromTo(".services-list-item1-title", { y: -100, opacity: 0.5 }, { y: 0, opacity: 1, ...titleScrollTrigger(1), duration: 3 });

        // second list item
        gsap.fromTo(".services-list-item2-image", { x: 80 }, { x: 0, ...imageScrollTrigger(2), duration: 3 });
        gsap.fromTo(".services-list-item2-content", { x: -80 }, { x: 0, ...textScrollTrigger(2), duration: 3 });
        gsap.fromTo(".services-list-item2-title", { y: -100, opacity: 0.5 }, { y: 0, opacity: 1, ...titleScrollTrigger(2), duration: 3 });
        
        // third list item
        gsap.fromTo(".services-list-item3-image", { x: -80 }, { x: 0, ...imageScrollTrigger(3), duration: 3 });
        gsap.fromTo(".services-list-item3-content", { x: 80 }, { x: 0, ...textScrollTrigger(3), duration: 3 });
        gsap.fromTo(".services-list-item3-title", { y: -100, opacity: 0.5 }, { y: 0, opacity: 1, ...titleScrollTrigger(3), duration: 3 });
        
        // fourth list item
        gsap.fromTo(".services-list-item4-image", { x: 80 }, { x: 0, ...imageScrollTrigger(4), duration: 3 });
        gsap.fromTo(".services-list-item4-content", { x: -80 }, { x: 0, ...textScrollTrigger(4), duration: 3 });
        gsap.fromTo(".services-list-item4-title", { y: -100, opacity: 0.5 }, { y: 0, opacity: 1, ...titleScrollTrigger(4), duration: 3 });
        
        // fifth list item
        gsap.fromTo(".services-list-item5-image", { x:-80 }, { x: 0, ...imageScrollTrigger(5), duration: 3 });
        gsap.fromTo(".services-list-item5-content", { x: 80 }, { x: 0, ...textScrollTrigger(5), duration: 3 });
        gsap.fromTo(".services-list-item5-title", { y: -100, opacity: 0.5 }, { y: 0, opacity: 1, ...titleScrollTrigger(5), duration: 3 });
    });

    const renderServices = servicesData.map(service => <div className={`services-list-item${service.id}`}>
        <div className={`services-list-item${service.id}-image`}>
            <div className='price-tag'>{service.price}€</div>
            <img src={service.imageSrc} alt={`Kitesurfing lesson - ${service.price}`}/>
        </div>
        <div className={`services-list-item${service.id}-content`}>
            <div className={`services-list-item${service.id}-title`}>
                <span>{service.tagline}</span>
                <h3>{service.title}</h3>
                <p className='subtitle'>{service.subtitle}</p>
            </div>
            <p className={`services-list-item${service.id}-text`} dangerouslySetInnerHTML={{ __html: service.description }} />
            <Button className="services-button" title="Book now" onClick={() => updateSelectedBooking(service.id)} />
        </div>
    </div>);


    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ background: '#283D58', marginBottom: '-2px' }}>
                <path fill="#E8F1E2" fill-opacity="1" d="M0,224L80,240C160,256,320,288,480,256C640,224,800,128,960,96C1120,64,1280,96,1360,112L1440,128L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
            <ServicesStyled id="services">
                <h2 className='services-title'>
                    Services
                </h2>
                <div className='services-list'>
                    {renderServices}
                </div>
            </ServicesStyled>
        </>
    );
};

export default Services;
import axios from 'axios';

const host = "https://reful.hr/api/v1/send-email"

export const sendBookingEmail = async (data) => {
    return new Promise((resolve, reject) => {
        axios.post(host, { data })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response ? error.response.data : error.message);
            });
    });
}
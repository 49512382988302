import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { ParallaxLayer } from '@react-spring/parallax';

import vukoImage from '../../assets/vuko.png';

import { AboutStyled } from './style';

const About = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const [imageLoaded, setImageLoaded] = React.useState(false);

    React.useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', setWindowWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', setWindowWidth(window.innerWidth));
        };
    }, []);

    React.useEffect(() => {
        if (imageLoaded) ScrollTrigger.refresh()
    }, [imageLoaded]);

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const content = document.querySelector(".content");

        gsap.fromTo(".about-title", 
            {
                y: -200,
                opacity: 0
            },
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: ".about-title",
                    scroller: content,
                    start: "top 80%",
                    end: "top 30%",
                    scrub: true
                },
                duration: 3
            }
        );
    });

    return (
        <AboutStyled id='about'>
            <h2 className='about-title'>
                About Us
            </h2>
            <div className='about-content'>
                At Surf Club Reful, we’re all about embracing the thrill of the water. Offering kitesurfing and stand-up paddleboarding (SUP), we provide a welcoming space for enthusiasts of all levels. Whether you're just starting out or looking to refine your skills, our expert instructors are here to help you make the most of every session.
                Join our community and experience the freedom of the wind with Surf Club Reful—your go-to destination for kitesurfing and SUP.
            </div>
            <h3 className='about-instrucotr-title'>
                Meet Our Head Instructor
            </h3>
            <div className='about-instructor-content'>
                Addicted to kitesurfing since 2016, Vuko has spent years traveling the globe, learning from different surf spots, and sharing the stoke with others. With a deep passion for nature and the sea, he have become an experienced instructor who loves helping others connect with the water and enjoy the thrill of surfing.<br/>
                When it’s not windy, Vuko stays active with highlining and free climbing, always chasing freedom in the outdoors. Whether on the water or on the rocks, he inspire students to find their own flow and embrace the adventure.<br/>
                <i>"Surfing is not just a sport—it’s a way of life, and I can’t wait to share it with you!"</i>
            </div>
            {windowWidth > 1300
                ? <ParallaxLayer
                    offset={0.5}
                    speed={0.3}
                    className='about-image'
                >
                    <img src={vukoImage} onLoad={() => setImageLoaded(true)} alt="Kitesurfing instructor" />
                </ParallaxLayer>
                : <div className='about-image'>
                    <img src={vukoImage} onLoad={() => setImageLoaded(true)} alt="Kitesurfing instructor2" />
                </div>
            }
        </AboutStyled>
    );
};

export default About;
import React from 'react';
import { ParallaxLayer } from '@react-spring/parallax';

import { BannerStyled } from './style';

import bannerBackground from '../../assets/banner.png';
import bannerMobileBackground from '../../assets/banner-mobile.png';
import bannerBackgroundMain from '../../assets/banner-background1.png';

import Button from '../UI/button';

const Banner = ({ setScrollTo }) => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', setWindowWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', setWindowWidth(window.innerWidth));
        };
    }, []);

    return (
        <BannerStyled id='home'>
            <ParallaxLayer
                offset={0}
                speed={0.1}
                className='banner-bubble'
            >
                <img src={bannerBackgroundMain} alt="Sea background sea bubble" />
            </ParallaxLayer>
            <ParallaxLayer
                offset={0}
                speed={0.4}
                className='banner-background'
            >
                <img src={windowWidth > 1100 ? bannerBackground : bannerMobileBackground} alt="Kitesurfing spot with kitesurfers"/>
            </ParallaxLayer>
            <ParallaxLayer
                offset={0}
                speed={0.2}
                className='banner-content'
            >
                <div className='banner-heading'>
                    <div className='banner-tagline'>powered by wind</div>
                    <h1 className='banner-title'>surf club reful</h1>
                    <div>
                        <div className="polygon"></div>
                        <p>Welcome to Surf Club Reful, your ultimate destination for surf enthusiasts and adventure seekers. Whether you're catching the wind, unwinding with friends, or soaking up the vibrant beach vibes, we offer the perfect mix of exciting kitesurfing culture, and all the energy you need to fuel your adventure.</p>
                    </div>
                    <Button title="Book now" onClick={() => setScrollTo(windowWidth > 1100 ? 2.1 : 2.9)} className='banner-button' />
                </div>
            </ParallaxLayer>
        </BannerStyled>
    );
};

export default Banner;
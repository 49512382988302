import styled from 'styled-components';

export const FooterStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #283D58;
    z-index: 100;
    position: relative;

    .content {
        display: flex;
        justify-content: space-between;
        background-color: $darkBlue;
        color: white;
        padding: 3rem 0;
        max-width: 1320px;
        box-sizing: border-box;
        width: 100%;
        
        @media (max-width: 767px) {
            flex-direction: column;
            text-align: center;
            padding: 15vw 5vw 7vw;
        }

        &-left {
            width: 30%;
            display: flex;
            flex-direction: column;
            margin-top: 30px;

            @media (max-width: 767px) {
                width: 100%;
                align-items: center;
                margin-top: 0;
            }

            .footer-logo {
                width: 200px;
                height: 85px;
            }
        }

        &-right {
            width: 40%;

            @media (max-width: 767px) {
                width: 100%;
            }

            h3 {
                margin-bottom: 35px;
                font-size: 30px;
                letter-spacing: 1.5px;
                font-weight: 400;
                font-style: normal;
                font-family: "Barlow Condensed", serif;
            }

            ul {
                list-style: none;
                padding: 0;
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 15px;

                li {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                    padding: 17px 0;

                    a {
                        display: inline-block;
                        text-decoration: none;
                        color: white;

                        &:hover {
                            color: $primaryColor;
                        }
                    }
                }
            }
        }
    }

    .social-links {
        display: flex;
        margin-top: 45px;

        @media (max-width: 767px) {
            margin-top: 25px;
        }

        svg {
            width: 40px;
            height: 40px;
            margin-right: 15px;
        }
    }

    .copyright {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        text-align: center;
        padding: 0 5vw 25px;
        color: white;
    }
`;
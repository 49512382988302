import styled, { keyframes } from 'styled-components';

const animate = keyframes`
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}`

export const MenuStyled = styled.div`
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 0;
    box-sizing: content-box;
    z-index: 1000;
    display: flex;

    .menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        margin: 10px;

        svg {
            width: 35px;
            height: 35px;
            z-index: 1;
        }

        .menu-background {
            width: 67px;
            height: 67px;
            background: #2c3749ff;
            border-radius: 50%;
            position: absolute;
            z-index: 0;
            right: 0;
            top: 0;
            margin: 10px;
            scale: 1;
        }
    }

    .menu-content {
        position: absolute;
        width: 100vw;
        height: 100lvh;
        z-index- 10000;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        scale: 0;
        gap: 2rem;

        span {
            color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            font-size: 25px;
            letter-spacing: 2px;
            font-weight: 600;
            color: #ffffff70;
            width: 80%;
            border-bottom: 0.5px solid #ffffff1a;
            padding-bottom: 5px;
        }

        &-item {
            display: flex;
            align-items: center;
            gap: 20px;
            padding: 0 2rem;  

            svg {
                width: 35px;
                height: 35px;
                fill: #ffffff70;
            }
        }
    }

    .menu-logo-animation {
        display: flex;
        justify-content: center;
        width: 100%;
        top: 4rem;
        transform: rotate(-8deg);
        position: absolute;
        z-index: -1;
        height: 150px;

        .menu-header-logo-main {
            stroke: #e3b50529;
            fill: transparent;
            filter: transparent;
        }

        .menu-header-logo {
            position: absolute;
            animation: ${animate} 4s ease-in-out infinite;
        }

        svg {
            width: 95%;
            height: 100%;
        }
    }

`;
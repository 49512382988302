import styled from 'styled-components';

export const LayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    .layout {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
`;
import React from 'react';
import { Parallax } from '@react-spring/parallax';

import Header from '../header';
import Banner from '../banner';
import Footer from '../footer';
import Menu from '../menu';
import Modal from '../UI/modal';

import { useStore } from '../../store';

import { LayoutStyled } from './style';

const Layout = props =>  {
    const rootRef = React.useRef(null);
    const [scrollTo, setScrollTo] = React.useState(0);
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
    const { selectedBooking, updateSelectedBooking } = useStore();

    React.useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', setWindowWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', setWindowWidth(window.innerWidth));
        };
    }, []);

    React.useEffect(() => {
        rootRef.current.scrollTo(scrollTo);
    }, [scrollTo]);

    return (
        <LayoutStyled>
            {windowWidth <= 1100 && <Menu setScrollTo={(value) => setScrollTo(value)}/>}
            <Parallax ref={rootRef} className='content'>
                <Header setScrollTo={(value) => setScrollTo(value)} />
                <Banner setScrollTo={(value) => setScrollTo(value)} />
                <div className='layout'>
                    {props.children}
                </div>
                <Footer />
            </Parallax>
            {selectedBooking !== 0 && <Modal title={selectedBooking} onClick={() => updateSelectedBooking(0)} />}
        </LayoutStyled>
    );
};

export default Layout;
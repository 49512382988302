import styled from 'styled-components';

export const ContactStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0 0;
    background: #283D58;
    font-family: "Barlow Condensed", serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    z-index: 10;
    position: relative;

    .contact-title {
        text-transform: uppercase;
        font-size: 64px;
        color: #E3B505;
        margin: 0;
        font-weight: 400;
    }

    .contact-content {
        width: 100%;
        max-width: 1200px;
        display: flex;
        justify-content: space-around;
        margin-bottom: 5rem;

        @media (max-width: 767px) {
            margin-bottom: 2rem;
        }

        &-item-0,
        &-item-1,
        &-item-2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 45px 0;
            width: 30%;
            box-sizing: border-box;

            @media (max-width: 991px) {
                width: 100%;
                margin-bottom: 20px;
            }

            img {
                height: 65px;
                width: auto;
            }

            h6 {
                font-size: 30px;
                margin: 1.5rem 0 1rem;
                font-weight: 500;
                line-height: 1;
            }

            p {
                font-weight: 300;
                line-height: 1.5;
                margin: 0;
                text-align: center;
            }

            a {
                text-decoration: none;
                transition: all 0.2s ease-in-out 0s;
                color: #E3B505;
            }
        }
    }
`;
import styled from 'styled-components';

export const AboutStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;
    font-family: "Barlow Condensed", serif;
    font-weight: 400;
    font-style: normal;
    color: #fff;
    position: relative;
    background: #283D58;
    position: relative;

    .about-title {
        text-transform: uppercase;
        font-size: 64px;
        z-index: 101;
        margin: 0;
        font-weight: 400;
    }

    .about-instrucotr-title {
        text-transform: uppercase;
        font-size: 34px;
        margin: 2rem 0 0;
        z-index: 101;
        font-weight: 400;
    }

    .about-content {
        line-height: 25px;
        font-family: system-ui;
        font-size: 18px;
        max-width: 50%;
        padding: 4rem 0 0;
        text-align: center;
        z-index: 101;
        
        @media (max-width: 800px) {
            max-width: 90%;
        }
    }

    .about-instructor-content {
        line-height: 25px;
        font-family: system-ui;
        font-size: 18px;
        max-width: 50%;
        padding: 2rem 0 0;
        text-align: center;
        z-index: 101;
        
        @media (max-width: 800px) {
            max-width: 90%;
        }
    }

    .about-image {
        width: 30%;
        max-width: 440px;
        justify-self: flex-end;
        margin-right: 2rem;
        z-index: 100;
        
        @media (max-width: 1300px) {
            width: 90%;
            margin-right: 0;
            margin-top: 3rem;
        }

        img {
            width: 100%;
            opacity: 0.8;
        }
    }
`;
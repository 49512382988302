import styled from 'styled-components';
import PriceTag from '../../assets/price-tag.png';

export const ServicesStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Barlow Condensed", serif;
    font-weight: 400;
    font-style: normal;
    padding: 5rem 0 0;
    background: #E8F1E2;
    position: relative;
    color: #00171fc7;

    .services-title {
        text-transform: uppercase;
        font-size: 64px;
        margin: 0;
        font-weight: 400;
    }

    .services-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 10rem;
        padding-top: 8rem;
        max-width: 1200px;

        @media (max-width: 1200px) {
            row-gap: 5rem;
            width: 95%;
        }

        @media (max-width: 700px) {
            padding-top: 4rem;
        }

        .price-tag {
            color: white;
            font-size: 20px;
            font-weight: 500;>
            z-index: 4;
            align-items: flex-start;
            margin-top: 2rem;
            display: flex;
            position: absolute;
            background-image: url(${PriceTag});
            background-position: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            justify-content: center;
            padding: .625rem 1.25rem;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            margin-left: -4px;
            width: 65px;
        }

        .input-fields1 {
            display: flex;
            flex-direction: column;
            opacity: 0;
            height: 0;
        }
    }


    .services-button {
        background: #107E7D;
        border: 1px solid #107E7D;
        color: #E8F1E2;

        &:hover {
            background: #0c5f5e;
            border: 1px solid #0c5f5e;
        }
    }

    .services-list-item1,
    .services-list-item2,
    .services-list-item3,
    .services-list-item4,
    .services-list-item5 {
        width: 100%;
        display: flex;
        column-gap: 5rem;
        z-index: 100;

        @media (max-width: 1200px) {
            width: 90%;
            gap: 2rem;
        }

        @media (max-width: 700px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &-image {
            display: flex;
            justify-content: flex-end;
            position: relative;
            width: 50%;
            align-items: center;
            max-height: 295px;
            overflow: hidden;

            @media (max-width: 700px) {
                width: 100%;
                display: flex;
                justify-content: center;
            }

            img {
                width: 80%;

                @media (max-width: 1200px) {
                    width: 100%;
                }
            }

            .price-tag {
                left: calc(20%);
                top: 0;

                @media (max-width: 1200px) {
                    left: 0;
                }
            }
        }

        &-content {
            width: 50%;

            @media (max-width: 700px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            span {
                font-size: 1.125rem;
                line-height: 1.3rem;
                color: #8f3985;
                text-transform: lowercase;
                letter-spacing: 3px;
            }

            h3 {
                text-transform: uppercase;
                font-size: 2rem;
                line-height: 2.3rem;
                font-weight: 500;
                margin: 0 0 .5rem;
            }

            ul {
                padding-right: 20px;
                margin-top: 0;
            }
            
            .subtitle {
                font-size: 18px;
                margin: 0;
            }
        }

        &-text {
            font-weight: 400;
            line-height: 1.75rem;
            margin-top: 1.5rem;
            font-family: system-ui;

            p {
                line-height: 20px;
            }
        }
    }

    .services-list-item2,
    .services-list-item4 {
        flex-direction: row-reverse;

        @media (max-width: 700px) {
            flex-direction: column;
        }

        &-image {
            display: flex;
            justify-content: flex-start;

            .price-tag {
                left: 0;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;

            @media (max-width: 700px) {
                align-items: flex-start;
                text-align: left;
            }

            ul {
                direction: rtl;
                padding-right: 20px;
                margin-top: 0;

                @media (max-width: 700px) {
                    direction: ltr;
                }
            }
        }
    }
`;

/*
.services-list {
        display: flex;
        align-items: center;
        gap: 5rem;
        padding-top: 5rem;

        &-item1,
        &-item2,
        &-item3,
        &-item4 {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
            height: 130px;
            border-radius: 50%;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                filter: blur(5px);
                background: #003459;
                border-radius: 50%;
            }

            img {
                max-width: 100px;
                position: relative;
            }    
        }
    }
*/
import React from 'react';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import { ParallaxLayer } from '@react-spring/parallax';

import surfer from '../../assets/voucher1.png'

import { VaucherStyled } from './style';

const Vaucher = () => {
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        setWindowWidth(window.innerWidth);
        window.addEventListener('resize', setWindowWidth(window.innerWidth));
        return () => {
            window.removeEventListener('resize', setWindowWidth(window.innerWidth));
        };
    }, []);

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        const content = document.querySelector(".content");

        gsap.fromTo(".vaucher-title", 
            {
                y: -200,
                opacity: 0
            },
            {
                y: 0,
                opacity: 1,
                scrollTrigger: {
                    trigger: ".vaucher-title",
                    scroller: content,
                    start: "top 80%",
                    end: "top 30%",
                    scrub: true
                },
                duration: 3
            }
        );
    });

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ background:"#E8F1E2", marginBottom: '-2px', marginTop: '-2px' }}>
                <path fill="#107E7D" fill-opacity="1" d="M0,192L1440,224L1440,320L0,320Z"></path>
            </svg>
            <VaucherStyled>
                <h2 className='vaucher-title'>
                    Gift the Thrill of Kitesurfing! 
                    {/* 🪁 */}
                </h2>
                <div className='vaucher-content'>
                    {windowWidth >= 1200 && <ParallaxLayer
                        offset={0.8}
                        speed={0.2}
                        style={{ display: 'flex', alignItems: 'center', zIndex: '100' }}
                    >
                        <img src={surfer} alt="Kitesurfing voucher"/>
                    </ParallaxLayer>}
                    <div className='vaucher-text'>
                        <div>
                            <h4>Kitesurfing Voucher</h4>
                            <p>Looking for a unique gift for someone adventurous? A <b>Kitesurfing Lesson Voucher</b> is the perfect way to give the gift of adrenaline and excitement! Whether it’s for a birthday, special occasion, or just because, this voucher promises an unforgettable experience.</p>
                        </div>
                        <ul>
                            <li>Flexible Lesson Options: Choose a single lesson or a multi-lesson package.</li>
                            <li>Perfect for Beginners or Advanced: Suitable for all skill levels.</li>
                            <li>Valid for 12 Months: Plenty of time to redeem and plan the perfect day!</li>
                        </ul>
                        <span>Contact us to get your gift voucher. <a href="mailto:info@reful.hr">info@reful.hr</a></span>
                    </div>
                    {windowWidth < 1200 && <img src={surfer} alt="Kitesurfing voucher"/>}
                </div>

            </VaucherStyled>
        </>
    );
};

export default Vaucher;
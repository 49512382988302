import styled from 'styled-components';

export const BannerStyled = styled.div`
    // background: linear-gradient(319deg, #107E7D 48%, #8f3985 58%);
    position: relative;
    background: #283D58;

    img {
        width: 100%;
    }

    .banner {
        &-content {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            z-index:20;
            max-width: 1320px;
            margin: 0 auto;
            z-index: 101;
        }

        &-heading {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            font-family: "Barlow Condensed", serif;
            font-weight: 400;
            font-style: normal;
            z-index: 10;
            margin-top: 35vh;
            max-width: 560px;
            text-align: right;
    
            @media (max-width: 1320px) {
                padding: 0 4rem;
                margin-top: 24vh;
                line-height: 1;
            }
    
            @media (max-width: 600px) {
                padding: 0 2rem;
                margin-top: 32vh;
            }

            @media (max-width: 400px) {
                margin-top: 50%;
            }

            .polygon {
                width: 245px;
                height: 145px;          
                float: left;
                shape-outside: polygon(100% 0, 0 100%, 3% 100%);
                clip-path: polygon(100% 0, 0 100%, 3% 100%);
                margin: 15px;

                @media (max-width: 500px) {
                    display: none;
                }
            } 

            p {
                line-height: 25px;
                font-family: system-ui;
                font-size: 14px;
                color: #E8F1E2;

                @media (max-width: 500px) {
                    line-height: 20px;
                }
            }
        }

        &-title {
            font-size: 64px;
            text-transform: uppercase;
            color: #E8F1E2;
            margin: 0;
            font-weight: 100;

            @media (max-width: 600px) {
                font-size: 40px;
            }
        }
        
        &-tagline {
            font-size: 25px;
            // color: #8f3985;
            color: #E3B505;
            text-transform: uppercase;
            letter-spacing: 14px;

            @media (max-width: 600px) {
                font-size: 16px;
                letter-spacing: 9px;
            }
        }
    }


    .banner-background {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative !important;
        z-index: 100;

        img {
            width: 100%;
            max-width: 1700px;

            @media (max-width: 1200px) {
                min-height: 100%;
                height: 100vh;
                width: auto;
            }
        }
    }

    .banner-bubble {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        @media (max-width: 1200px) {
            justify-content: flex-start;
        }

        img {
            width: 100%;

            @media (max-width: 1200px) {
                min-height: 100%;
                height: 100vh;
                width: auto;
            }
        }
    }

    .banner-button {
        justify-self: flex-end;
        color: #E8F1E2;
        position: relative;
        margin-top: 2rem;

        &:hover:after {
            background: #107e7dcf;
        }

        &:after {
            content: '';
            background: #107E7D;
            border-radius: 50px;
            filter: blur(7px);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            z-index: -1;

            @media (max-width: 1100px) {
                content: none;
            }
        }
    }
`;
import styled from 'styled-components';

export const VaucherStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 5rem;
    background: #107E7D;
    font-family: "Barlow Condensed", serif;
    font-weight: 400;
    font-style: normal;

    .vaucher-title {
        text-transform: uppercase;
        font-size: 64px;
        color: #E3B505;
        text-align: center;
        line-height: 1;
        margin: 0;
        font-weight: 400;
    }

    .vaucher-content {
        width: 100%;
        max-width: 1320px;
        display: flex;
        justify-content: flex-end;
        margin: 3rem 0 2rem;
        position: relative;

        @media (max-width: 1200px) {
            justify-content: center;
            flex-direction: column;
            width: 90%;
            max-width: 800px;
            gap: 2rem;
        }

        img {
            max-width: 500px;
            width: 50%;
            right: -5%;
            position: relative;

            @media (max-width: 1200px) {
                width: 100%;
                right: 0;
                max-width: unset;
            }
        }

        .vaucher-text {
            width: 50%;
            font-size: 18px;
            color: #00171fc7;
            z-index: 101;

            @media (max-width: 1200px) {
                width: 100%;
            }

            h4 {
                text-transform: uppercase;
                font-size: 1.8rem;
                line-height: 2.3rem;
                font-weight: 500;
                margin: 0 0 .5rem;
                color: white;
            }

            p, ul {
                font-weight: 400;
                font-size: 1.125rem;
                margin-top: 1.5rem;
                font-family: system-ui;
                color: white;
            }

            li::marker {
                color: #E3B505;
                font-size: 1.5rem;
            }

            a {
                color: #E3B505;
            }

            span {
                font-size: 20px;
                color: white;
            }
        }
    }
`;